import BotMessage from '@/components/messages/bot-messages/BotMessage/BotMessage.vue';
import TypingIndicatorMessage from '@/components/messages/bot-messages/TypingIndicatorMessage/TypingIndicatorMessage.vue';
import UserMessage from '@/components/messages/user-messages/UserMessage/UserMessage.vue';
import SuggestionsContainer from '@/components/suggestions/SuggestionsContainer/SuggestionsContainer.vue';
import { IConversationBlock, ISuggestion, IUserMessage } from '@/models/bot-models';
import TWEEN from '@tweenjs/tween.js';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';

@Component({
    computed: {
        ...mapState(['conversationBlocks', 'currentUserMessage', 'isTyping', 'suggestions']),
        ...mapState('scroll', ['loadedObs']),
    },
    components: { UserMessage, BotMessage, TypingIndicatorMessage, SuggestionsContainer },
})
export default class MessagesContainer extends Vue {
    public conversationBlocks!: IConversationBlock[];
    public currentUserMessage!: IUserMessage;
    public suggestions!: ISuggestion[];
    public isTyping!: boolean;
    public offsetFromBottom: number = 0;
    @Prop() public chatIsClosed!: boolean;
    public pendingUserMessage = false;
    private readonly loadedObs!: Subject<void>;

    public registerScroll() {
        this.offsetFromBottom = this.$el.scrollHeight - this.$el.clientHeight - this.$el.scrollTop;
    }

    public mounted() {
        this.directAutoScroll();
        this.loadedObs.subscribe(this.autoScroll);
    }

    @Watch('chatIsClosed')
    public onChatIsClosedChange() {
        if (!this.chatIsClosed) {
            this.directAutoScroll();
        }
    }

    @Watch('conversationBlocks')
    public onConversationBlocksChange() {
        const lastConversationBlock: IConversationBlock | undefined = _.last(this.conversationBlocks);
        if (
            lastConversationBlock &&
            lastConversationBlock.user_message &&
            this.currentUserMessage &&
            _.isEqual(lastConversationBlock.user_message.content_type, this.currentUserMessage.content_type) &&
            _.isEqual(lastConversationBlock.user_message.content, this.currentUserMessage.content)
        ) {
            this.pendingUserMessage = false;
        }
    }

    @Watch('isTyping')
    public onIsTypingChange() {
        if (this.isTyping) {
            this.pendingUserMessage = true;
        }
    }

    @Watch('currentUserMessage')
    public onCurrentUserMessageChange() {
        if (this.currentUserMessage === null && this.conversationBlocks && this.conversationBlocks.length > 0) {
            this.pendingUserMessage = false;
        }
    }

    public autoScroll() {
        if (this.offsetFromBottom < 10) {
            this.smoothAutoScroll();
        }
    }

    private directAutoScroll() {
        this.$el.scrollTop = this.$el.scrollHeight;
    }

    private smoothAutoScroll() {
        function animate() {
            if (TWEEN.update()) {
                requestAnimationFrame(animate);
            }
        }

        requestAnimationFrame(animate);

        let lastMessageBlock = this.$el.children[this.$el.children.length - 1] as HTMLElement;
        let distanceFromBottomOfDestination = this.$el.scrollTop;
        const scrollAmount = { y: this.$el.scrollTop };

        if (typeof lastMessageBlock !== 'undefined') {
            let suggestionsBlock;
            let hasSuggestions = false;

            // If there are suggestions, lastBlock is the suggestion block and lastMessageBlock is the
            // antepenultimate block
            if (lastMessageBlock.classList.contains(this.$style.suggestions)) {
                suggestionsBlock = lastMessageBlock;
                lastMessageBlock = this.$el.children[this.$el.children.length - 2] as HTMLElement;
                hasSuggestions = true;
            }

            if (lastMessageBlock.classList.contains(this.$style['typing-indicator-message'])) {
                // If there is a typing indicator message, user message can only be the antepenultimate
                const lastUserMessageBlock = this.$el.children[this.$el.children.length - 2] as HTMLElement;
                if (
                    typeof lastUserMessageBlock !== 'undefined' &&
                    lastUserMessageBlock.classList.contains(this.$style['user-message'])
                ) {
                    // If there is a user message, the distance from bottom of destination contains the height of the
                    // last user message
                    distanceFromBottomOfDestination = lastUserMessageBlock.clientHeight;
                } else {
                    // otherwise it is 0
                    distanceFromBottomOfDestination = 0;
                }
                // We add the typing indicator message block height
                distanceFromBottomOfDestination += lastMessageBlock.clientHeight;
            } else {
                const firstMessageOfLastBlock = lastMessageBlock.children[0] as HTMLElement;

                // Margin top is 16px and margin bottom is also 16px for every message bubble
                const heightOfLastUserMessage =
                    typeof firstMessageOfLastBlock !== 'undefined' &&
                    firstMessageOfLastBlock.classList.contains(this.$style['user-message'])
                        ? firstMessageOfLastBlock.clientHeight + 16
                        : 0;

                // suggestions container has 16px margin bottom
                const heightOfSuggestions = hasSuggestions && suggestionsBlock ? suggestionsBlock.clientHeight + 16 : 0;

                distanceFromBottomOfDestination = Math.max(
                    this.$el.clientHeight,
                    // messages container has also 16px margin top and bottom
                    lastMessageBlock.clientHeight + 32 - heightOfLastUserMessage + heightOfSuggestions,
                );
            }
        }

        new TWEEN.Tween(scrollAmount)
            .to({ y: this.$el.scrollHeight - distanceFromBottomOfDestination }, 200)
            .onUpdate(() => {
                this.$el.scrollTop = scrollAmount.y;
            })
            .start();
    }
}
