import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';

@Component({
    computed: {
        ...mapState(['chatErrorMessage']),
    },
})
export default class ChatErrorHeader extends Vue {
    public chatErrorMessage!: string | null;
}
