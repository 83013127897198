import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue from 'vue';
import VueTypeScriptInject from 'vue-typescript-inject';
import App from './App/App.vue';
import store from './store';

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.config.productionTip = false;
Vue.use(VueTypeScriptInject);

new Vue({
    store,
    render: (h) => h(App),
}).$mount('#randy-webchat', true);
