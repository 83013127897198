import LocationSuggestion from '@/components/suggestions/LocationSuggestion/LocationSuggestion.vue';
import PostbackSuggestion from '@/components/suggestions/PostbackSuggestion/PostbackSuggestion.vue';
import { ISuggestion } from '@/models/bot-models';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { VueConstructor } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

library.add(faAngleRight);
library.add(faAngleLeft);

@Component({
    components: { LocationSuggestion, PostbackSuggestion },
})
export default class SuggestionsContainer extends Vue {
    @Prop() public suggestions!: ISuggestion[];
    public canGoRight = true;
    public canGoLeft = false;
    public wrapperLeft = 0;
    private currentPageIdx = 0;
    private leftOffsets: number[] = [0];
    private containerEl!: HTMLElement;

    private suggestionComponentsByNames: { [key: string]: VueConstructor<Vue> } = {
        location: LocationSuggestion,
        text: PostbackSuggestion,
    };

    public componentName(suggestionType: string): VueConstructor<Vue> {
        return this.suggestionComponentsByNames[suggestionType];
    }

    public mounted(): void {
        this.containerEl = this.$el as HTMLElement;
        this.canGoLeft = false;
        this.canGoRight = this.containerEl.scrollWidth > this.containerEl.clientWidth;
        if (this.canGoRight) {
            this.leftOffsets = this.computeOffsets();
        }
    }

    public goToNextSuggestion() {
        this.currentPageIdx += 1;
        this.wrapperLeft = this.leftOffsets[this.currentPageIdx];
        this.updateArrowsVisibility();
    }

    public goToPreviousSuggestion() {
        this.currentPageIdx -= 1;
        this.wrapperLeft = this.leftOffsets[this.currentPageIdx];
        this.updateArrowsVisibility();
    }

    private updateArrowsVisibility() {
        this.canGoLeft = this.currentPageIdx > 0;
        this.canGoRight = this.currentPageIdx < this.leftOffsets.length - 1;
    }

    private computeOffsets() {
        let firstIdx = 0;
        const leftOffsets: number[] = [0];
        const suggestionsWrapper = this.getSuggestionsWrapper();
        while (firstIdx <= suggestionsWrapper.children.length - 1) {
            const lastNextFullySeenSuggestion = this.getLastNextFullySeenSuggestion(
                firstIdx,
                leftOffsets[leftOffsets.length - 1],
            );
            if (lastNextFullySeenSuggestion < suggestionsWrapper.children.length) {
                leftOffsets.push(
                    -((suggestionsWrapper.children[lastNextFullySeenSuggestion] as HTMLElement).offsetLeft - 30),
                );
            }
            firstIdx = lastNextFullySeenSuggestion + 1;
        }
        return leftOffsets;
    }

    private getLastNextFullySeenSuggestion(firstIdx = 0, lastLeftOffset = 0): number {
        const suggestionsWrapper = this.getSuggestionsWrapper();
        const suggestions = suggestionsWrapper.children;
        const width = this.containerEl.clientWidth;
        let idx = firstIdx;
        // Arrow buttons are 20px width + 6px padding + 1px margin each (total width 34px)
        // so we need to check that offset + width is below width - 44px (we want a margin from button of 10px)
        // Concerning left side, we just need to have relative offset more than 30px
        while (
            idx <= suggestions.length - 1 &&
            // @ts-ignore
            // This is made to check if fully visible between arrows buttons (left side)
            (suggestions[idx].offsetLeft + lastLeftOffset >= 30 || idx === 0) &&
            // @ts-ignore
            // This is made to check if fully visible between arrows buttons (right side)
            suggestions[idx].offsetLeft + suggestions[idx].clientWidth + lastLeftOffset <= width - 44
        ) {
            idx += 1;
        }
        return idx;
    }

    private getSuggestionsWrapper(): HTMLElement {
        let suggestionsWrapper = this.containerEl.children[0];
        if (suggestionsWrapper.classList.contains(this.$style['suggestion-arrow'])) {
            suggestionsWrapper = this.containerEl.children[1];
        }
        return suggestionsWrapper as HTMLElement;
    }
}
