import PostbackButton from '@/components/buttons/PostbackButton/PostbackButton.vue';
import UrlButton from '@/components/buttons/UrlButton/UrlButton.vue';
import OnMountIsLoadedMixin from '@/mixins/on-mount-is-loaded';
import { IButton } from '@/models/bot-models';
import { VueConstructor } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: { UrlButton, PostbackButton },
})
export default class ButtonsContainer extends OnMountIsLoadedMixin {
    @Prop({ default: () => [] }) public buttons!: IButton[];

    private buttonComponentsByNames: { [key: string]: VueConstructor<Vue> } = {
        postback: PostbackButton,
        url: UrlButton,
    };

    public componentName(buttonType: string): VueConstructor<Vue> {
        return this.buttonComponentsByNames[buttonType];
    }
}
