import UserLocationMessage from '@/components/messages/user-messages/UserLocationMessage/UserLocationMessage.vue';
import UserPostbackMessage from '@/components/messages/user-messages/UserPostbackMessage/UserPostbackMessage.vue';
import UserTextMessage from '@/components/messages/user-messages/UserTextMessage/UserTextMessage.vue';
import DateIndicator from '@/components/misc/DateIndicator/DateIndicator.vue';
import { IUserMessage } from '@/models/bot-models';
import { VueConstructor } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';

@Component({
    components: { UserLocationMessage, UserPostbackMessage, UserTextMessage, DateIndicator },
})
export default class UserMessage extends Vue {
    @Prop({ default: null }) public message!: IUserMessage;
    @Prop({ default: Date.now() }) public date!: number;

    public showDate: boolean = false;

    private userMessageComponentsByName: { [key: string]: VueConstructor<Vue> } = {
        location: UserLocationMessage,
        postback: UserPostbackMessage,
        text: UserTextMessage,
    };

    public showMessageDate(): void {
        this.showDate = true;
    }

    public hideMessageDate(): void {
        this.showDate = false;
    }

    get componentName(): VueConstructor<Vue> {
        return this.userMessageComponentsByName[this.message.content_type];
    }
}
