import store from '@/store';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';

library.add(faTimes);
const HOSTNAME_REGEX = /(https?:\/\/[A-Za-z0-9\._\-]*:?\d*)\/.*/;

@Component({
    computed: {
        ...mapState(['iframeSrc']),
    },
})
export default class IframeVisualizer extends Vue {
    public isIframeLoaded: boolean = false;
    public iframeSrc!: string;
    public iframeUrl: string = '';
    private containerEl!: HTMLElement;

    public mounted(): void {
        this.containerEl = this.$el as HTMLElement;
        this.containerEl.style.display = 'none';
        window.addEventListener('message', this.receiveMessage, false);
    }

    public closeIframe() {
        store.commit('setIframeSrc', null);
        this.iframeUrl = '';
        setTimeout(() => {
            this.containerEl.style.display = 'none';
        }, 200);
    }

    public closeIfOutsideOfWrapper($event: MouseEvent) {
        if (($event.target as HTMLElement).className === 'iframe-container') {
            this.closeIframe();
        }
    }

    @Watch('iframeSrc')
    private onIframeSrcChanged() {
        if (this.iframeSrc !== null) {
            this.containerEl.style.display = 'flex';
            this.isIframeLoaded = false;
            this.iframeUrl = this.computeiFrameSrc();
            const iframeElement = this.containerEl.querySelector('iframe');
            if (iframeElement) {
                iframeElement.addEventListener(
                    'load',
                    () => {
                        this.isIframeLoaded = true;
                    },
                    { once: true },
                );
            }
        }
    }

    private receiveMessage(event: any) {
        if (event.origin !== this.extractHostname(this.iframeSrc)) {
            return;
        }
        if (event.data === 'close') {
            this.closeIframe();
        }
    }

    private extractHostname(url: string) {
        if (url) {
            const regexpMatch = url.match(HOSTNAME_REGEX);
            if (regexpMatch !== null) {
                return regexpMatch[1];
            }
            return url;
        }
    }

    private computeiFrameSrc(): string {
        let iframeSrc = this.iframeSrc;
        if (!iframeSrc) {
            return '';
        }
        if (iframeSrc.indexOf('?') > -1) {
            iframeSrc += '&';
        } else {
            iframeSrc += '?';
        }
        iframeSrc += 'webchat=true';
        return iframeSrc;
    }
}
