import OnMountIsLoadedMixin from '@/mixins/on-mount-is-loaded';
import { IPostbackSuggestion } from '@/models/bot-models';
import ChatService from '@/services/chat';
import { Component, Prop } from 'vue-property-decorator';
import { inject } from 'vue-typescript-inject';

@Component
export default class PostbackSuggestion extends OnMountIsLoadedMixin {
    @Prop() public suggestion!: IPostbackSuggestion;
    @inject() private readonly chatService!: ChatService;

    public sendSuggestion(): void {
        this.chatService.sendPostbackSuggestion(this.suggestion);
    }
}
