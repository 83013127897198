import { Subject } from 'rxjs';
import { MutationTree } from 'vuex';

export interface FluidAutoScrollState {
    loadedObs: Subject<void>;
}

const scrollState: FluidAutoScrollState = {
    loadedObs: new Subject(),
};

const storeMutations: MutationTree<FluidAutoScrollState> = {
    registerLoadedEvent(state: FluidAutoScrollState) {
        state.loadedObs.next();
    },
};

const fluidAutoScrollStore = {
    namespaced: true,
    state: scrollState,
    mutations: storeMutations,
};

export default fluidAutoScrollStore;
