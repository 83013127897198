import ButtonsContainer from '@/components/buttons/ButtonsContainer/ButtonsContainer.vue';
import { ICarouselElement } from '@/models/bot-models';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: { ButtonsContainer },
})
export default class BotCarouselMessageSlide extends Vue {
    @Prop({ default: () => ({}) }) public element!: ICarouselElement;
    public imageRatio: number = 0;

    public created(): void {
        const img = new Image();
        img.addEventListener('load', () => {
            this.imageRatio = Math.floor((img.height / img.width) * 100);
            this.$emit('elementLoad');
        });
        img.src = this.element.image_url;
    }
}
