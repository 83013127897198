import OnMountIsLoadedMixin from '@/mixins/on-mount-is-loaded';
import { IPostbackButton } from '@/models/bot-models';
import ChatService from '@/services/chat';
import { Component, Prop } from 'vue-property-decorator';
import { inject } from 'vue-typescript-inject';

@Component
export default class PostbackButton extends OnMountIsLoadedMixin {
    @Prop({ default: () => ({}) }) public button!: IPostbackButton;
    @inject() private readonly chatService!: ChatService;

    public sendPostback(): void {
        this.chatService.sendPostbackButton(this.button);
    }
}
