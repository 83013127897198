import OnMountIsLoadedMixin from '@/mixins/on-mount-is-loaded';
import { ILocationSuggestion } from '@/models/bot-models';
import ChatService from '@/services/chat';
import store from '@/store';
import { Component, Prop } from 'vue-property-decorator';
import { inject } from 'vue-typescript-inject';

@Component
export default class LocationSuggestion extends OnMountIsLoadedMixin {
    @Prop() public suggestion!: ILocationSuggestion;
    public isLoading = false;
    @inject() private readonly chatService!: ChatService;

    public mounted(): void {
        if (!('geolocation' in navigator)) {
            store.commit('setSuggestions', []);
        }
    }

    public sendSuggestion(): void {
        if (!this.isLoading) {
            this.isLoading = true;
            navigator.geolocation.getCurrentPosition(
                (pos) => {
                    this.isLoading = false;
                    this.chatService.sendLocationSuggestion(pos);
                },
                () => {
                    this.isLoading = false;
                    store.commit('setSuggestions', []);
                    store.dispatch('showChatErrorMessage', {
                        content:
                            'Impossible de récupérer votre localisation, veuillez écrire votre ville ou code postal',
                    });
                },
            );
        }
    }
}
