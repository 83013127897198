import ButtonsContainer from '@/components/buttons/ButtonsContainer/ButtonsContainer.vue';
import BotAudioMessage from '@/components/messages/bot-messages/BotAudioMessage/BotAudioMessage.vue';
import BotCommandMessage from '@/components/messages/bot-messages/BotCommandMessage/BotCommandMessage.vue';
import BotImageMessage from '@/components/messages/bot-messages/BotImageMessage/BotImageMessage.vue';
import BotTextMessage from '@/components/messages/bot-messages/BotTextMessage/BotTextMessage.vue';
import BotCarouselMessage from '@/components/messages/bot-messages/carousel/BotCarouselMessage/BotCarouselMessage.vue';
import DateIndicator from '@/components/misc/DateIndicator/DateIndicator.vue';
import { IBotMessage } from '@/models/bot-models';
import { VueConstructor } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {
        BotAudioMessage,
        BotCarouselMessage,
        BotCommandMessage,
        BotImageMessage,
        BotTextMessage,
        ButtonsContainer,
        DateIndicator,
    },
})
export default class BotMessage extends Vue {
    @Prop({ default: null }) public message!: IBotMessage;
    @Prop({ default: null }) public date!: number;

    public showDate: boolean = false;

    private botMessageComponentsByName: { [key: string]: VueConstructor<Vue> } = {
        audio: BotAudioMessage,
        carousel: BotCarouselMessage,
        command: BotCommandMessage,
        image: BotImageMessage,
        text: BotTextMessage,
    };

    public showMessageDate(): void {
        this.showDate = true;
    }

    public hideMessageDate(): void {
        this.showDate = false;
    }

    get componentName(): VueConstructor<Vue> {
        return this.botMessageComponentsByName[this.message.content_type];
    }
}
