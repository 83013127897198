import MessageMixin from '@/mixins/message-mixin';
import { IAudioBotMessageContent } from '@/models/bot-models';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import { Component, Prop } from 'vue-property-decorator';

library.add(faPlay);
library.add(faPause);

@Component
export default class BotAudioMessage extends MessageMixin {
    @Prop({ default: () => ({}) }) public content!: IAudioBotMessageContent;
    public isPlaying = false;
    public startedAtLeastOnce = false;
    public totalDuration: string = '';
    public currentDuration: string = '';
    private audio!: HTMLAudioElement;

    public created(): void {
        this.audio = new Audio(this.content.url);
        this.audio.addEventListener('loadedmetadata', () => {
            this.currentDuration = this.displayDuration(0);
            this.totalDuration = this.displayDuration(this.audio.duration);
        });
        this.audio.addEventListener('timeupdate', () => {
            this.currentDuration = this.displayDuration(this.audio.currentTime);
        });
        this.audio.addEventListener('ended', () => {
            this.isPlaying = false;
            this.startedAtLeastOnce = false;
            this.currentDuration = this.displayDuration(0);
        });
    }

    public playExtract() {
        this.audio.play().then(() => {
            this.isPlaying = true;
            this.startedAtLeastOnce = true;
        });
    }

    public pauseExtract() {
        this.isPlaying = false;
        this.audio.pause();
    }

    private displayDuration(seconds: number): string {
        const ceiledSeconds = Math.ceil(seconds);
        const numberOfHours = Math.floor(ceiledSeconds / 3600);
        const numberOfMinutes = Math.floor((ceiledSeconds - numberOfHours * 3600) / 60);
        const numberOfSeconds = Math.floor(ceiledSeconds - numberOfHours * 3600 - numberOfMinutes * 60);
        let durationStr = '';
        if (numberOfHours > 0) {
            durationStr += numberOfHours <= 9 ? `0${numberOfHours}:` : `${numberOfHours}:`;
        }
        durationStr += numberOfMinutes <= 9 ? `0${numberOfMinutes}:` : `${numberOfMinutes}:`;
        durationStr += numberOfSeconds <= 9 ? `0${numberOfSeconds}` : `${numberOfSeconds}`;
        return durationStr;
    }
}
