import { render, staticRenderFns } from "./BotAudioMessage.vue?vue&type=template&id=8ea7f8e8&"
import script from "./BotAudioMessage.ts?vue&type=script&lang=ts&"
export * from "./BotAudioMessage.ts?vue&type=script&lang=ts&"
import style0 from "./BotAudioMessage.scss?vue&type=style&index=0&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports