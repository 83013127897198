import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DateIndicator extends Vue {
    @Prop({ default: null }) public date!: number;

    get isToday(): boolean {
        const messageDate = new Date(this.date);
        const now = new Date();
        return (
            messageDate.getFullYear() === now.getFullYear() &&
            messageDate.getMonth() === now.getMonth() &&
            messageDate.getDate() === now.getDate()
        );
    }

    get dateDay(): string {
        const messageDate = new Date(this.date);
        return `${this.day(messageDate)}/${this.month(messageDate)}/${this.year(messageDate)}`;
    }

    get dateTime(): string {
        const messageDate = new Date(this.date);
        return `${this.hours(messageDate)}:${this.minutes(messageDate)}`;
    }

    private minutes(date: Date): string {
        return date
            .getMinutes()
            .toString()
            .padStart(2, '0');
    }

    private hours(date: Date): string {
        return date
            .getHours()
            .toString()
            .padStart(2, '0');
    }

    private day(date: Date): string {
        return date
            .getDate()
            .toString()
            .padStart(2, '0');
    }

    private month(date: Date): string {
        return (date.getMonth() + 1).toString().padStart(2, '0');
    }

    private year(date: Date): string {
        return date
            .getFullYear()
            .toString()
            .slice(2);
    }
}
