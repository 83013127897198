import OnMountIsLoadedMixin from '@/mixins/on-mount-is-loaded';
import { IUrlButton } from '@/models/bot-models';
import store from '@/store';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class UrlButton extends OnMountIsLoadedMixin {
    @Prop({ default: () => ({}) }) public button!: IUrlButton;

    public seeUrl() {
        if (this.button.target_blank === true) {
            window.open(this.button.url, '_blank');
        } else {
            store.commit('setIframeSrc', this.button.url);
        }
    }
}
